/*
Theme Color
By Default
Responsive Show / Hide
Theme Text
Theme Background
Theme Border
Theme Button
Scrollbar
Responsive Navbar Routes
Typewriter Effect
Carousel
ComponentHeader
iframe
As Per Requirements
*/

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

/* Theme Color */
:root {
  --primary-color: #a1009b;
  --primary-color-light: #d844c5;
  --primary-color-dark: #7c006e;
  --primary-bg-color: #fff1fe;
  --primary-color-shadow: #a70fa211;
  --primary-color-shadow-2: rgba(161, 0, 156, 0.25);
  --theme-success: #3ea743;
  --white: #fff;
  --dark: #343a40;
  --dark2: #00000020;
}

/* By Default */
a {
  text-decoration: none;
}

li {
  list-style: none;
}

p {
  margin-bottom: 0;
}

/* Responsive Show / Hide */
.d-none-sm {
  display: block !important;
}
.d-block-sm {
  display: none !important;
}
@media (max-width: 600px) {
  .d-none-sm {
    display: none !important;
  }
  .d-block-sm {
    display: block !important;
  }
}

/* Theme Text */
.text-primary-theme {
  color: var(--primary-color) !important;
}
.text-success-theme {
  color: var(--theme-success) !important;
}
.text-primary-light-theme {
  color: var(--primary-color-light) !important;
}
.text-primary-dark-theme {
  color: var(--primary-color-dark) !important;
}

/* Theme Background */
.bg-primary-theme {
  background-color: var(--primary-color);
}
.bg-primary-light-theme {
  background-color: var(--primary-bg-color);
}

.bg-success-theme {
  background-color: var(--theme-success);
}

/* Theme Border */
.border-primary-theme {
  border-color: var(--primary-color) !important;
}
.border-primary-light-theme {
  border-color: var(--primary-color-light) !important;
}
.border-primary-dark-theme {
  border-color: var(--primary-color-dark) !important;
}

/* Theme Button */
.btn-primary-theme {
  color: var(--white);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  cursor: pointer;
  transition: all 0.4s linear;
}
.btn-primary-theme:hover {
  background-color: var(--primary-color-light);
  color: var(--white);
}
.btn-primary-theme:active {
  background-color: var(--primary-color-dark);
  color: var(--white);
  border-color: var(--primary-color-dark);
}

.btn-outline-primary-theme {
  color: var(--primary-color);
  background-color: var(--white);
  border-color: var(--primary-color);
  cursor: pointer;
  transition: all 0.4s linear;
}

.btn-outline-primary-theme:hover {
  background-color: var(--primary-color);
  color: var(--white);
}

.btn-outline-primary-theme:active {
  background-color: var(--primary-color-dark);
  border-color: var(--primary-color-dark);
  color: var(--white);
}

.btn-secondary-theme {
  background-color: #e9e9ff;
  color: var(--primary-color-dark);
  border-color: #e9e9ff;
  cursor: pointer;
  transition: all 0.4s linear;
}
.btn-secondary-theme:hover {
  background-color: #9393f7;
  border-color: #9393f7;
}
.btn-secondary-theme:active {
  background-color: var(--primary-color-dark);
  color: var(--white);
  border-color: var(--primary-color-dark);
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background: var(--dark2);
}
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color-light);
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Typewriter Effect */
.typewriter-text {
  overflow: hidden;
  border-right: 0.15em solid var(--primary-color);
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: typing 3.5s steps(30, end) infinite,
    blink-caret 0.5s step-end infinite;
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: var(--primary-color);
  }
}

/* Carousel */
.carousel-slide {
  position: relative;
  height: 600px;
}

.carousel-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.carousel-title,
.carousel-text {
  z-index: 1;
}

.carousel-title .last-word {
  color: var(--primary-color);
}

@media (max-width: 600px) {
  .carousel-background {
    background-position: right;
  }
  .carousel-title {
    color: var(--white);
    text-shadow: 0 0 1px var(--primary-color);
  }
  .carousel-text {
    color: var(--primary-color-light);
    text-shadow: 0 0 0.5px var(--dark2);
  }
}

/* ComponentHeader */
.component-header {
  position: relative;
  display: flex;
  justify-content: center;
}

.component-header-text {
  text-shadow: 0 0 0.5px var(--dark2);
  border-left: 4px solid;
  font-size: 2.5rem;
}

.component-header-title {
  font-size: 70px;
  letter-spacing: 5px;
  opacity: 0.1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

@media (max-width: 768px) {
  .component-header-text {
    font-size: 1.5rem;
  }
  .component-header-title {
    font-size: 40px;
  }
}

/* iframe */
.iframe-wrapper {
  width: 100%;
  height: 500px;
}

/* As Per Requirements */

.px-responsive {
  padding-right: var(--bs-gutter-x, 1.5rem);
  padding-left: var(--bs-gutter-x, 1.5rem);
}

@media (min-width: 576px) {
  .px-responsive {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
}

@media (min-width: 768px) {
  .px-responsive {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
}

@media (min-width: 992px) {
  .px-responsive {
    padding-right: 5rem;
    padding-left: 5rem;
  }
}

@media (min-width: 1400px) {
  .px-responsive {
    padding-right: 7rem;
    padding-left: 7rem;
  }
}

.h-100vh {
  height: 100vh;
}

.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px !important;
  height: 36px !important;
  transition: background-color 0.3s ease;
}

.remove-after-icon::after {
  content: none !important;
}

.img-cover-center {
  object-fit: cover;
  object-position: center;
}
.img-contain-center {
  object-fit: contain;
  object-position: center;
}

.pointer {
  cursor: pointer;
}
.pointer-default {
  cursor: default;
}

.fs-sm {
  font-size: 14px;
}

.sticky-header {
  z-index: 10;
  position: sticky;
  top: 0;
}

.inner-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.08) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.05) 0px -79px 40px 0px inset,
    rgba(0, 0, 0, 0.03) 0px 2px 1px, rgba(0, 0, 0, 0.04) 0px 4px 2px,
    rgba(0, 0, 0, 0.04) 0px 8px 4px, rgba(0, 0, 0, 0.04) 0px 16px 8px,
    rgba(0, 0, 0, 0.04) 0px 32px 16px;
}

@media (min-width: 768px) {
  .mb-md-3rem {
    margin-bottom: 3rem;
  }
}

@media (max-width: 767.98px) {
  .mb-md-3rem {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .col-reverse-md {
    flex-direction: column-reverse;
  }
}

.form-control:focus {
  color: var(--dark);
  background-color: var(--white);
  border-color: var(--primary-color);
  outline: 0;
  box-shadow: 0 0 0 0.2rem var(--primary-color-shadow-2);
}
